import { Popover, Transition } from '@headlessui/react';
import { useCartContext, useCartState } from 'packages/framework/context/cart';
import { useCartTrigger } from 'packages/framework/hooks/Header/useCartTrigger';
import { useMiniCart } from 'packages/framework/hooks/MiniCart/useMiniCart';
import { lazy, Suspense, useEffect, useRef } from 'react';
import operations from '../MiniCart/miniCart.gql';
import MiniCartLoader from '../MiniCart/miniCartLoader';
import { GET_ITEM_COUNT_QUERY } from './cartTrigger.gql';

const MiniCart = lazy(() => import('../MiniCart'));

const CartTrigger = () => {
  const { itemCount } = useCartTrigger({
    queries: {
      getItemCountQuery: GET_ITEM_COUNT_QUERY,
    },
  });

  const cartBtnRef = useRef();

  return (
    <Popover className="flow-root text-sm lg:relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className="group -m-2 p-2.5 flex items-center relative mr-0"
            ref={cartBtnRef}
          >
            <svg
              id="Group_2847"
              data-name="Group 2847"
              xmlns="http://www.w3.org/2000/svg"
              width="22.452"
              height="18.626"
              viewBox="0 0 22.452 18.626"
            >
              <path
                id="Path_3029"
                data-name="Path 3029"
                d="M232.494,362.86a3.059,3.059,0,1,0,3.059,3.059A3.068,3.068,0,0,0,232.494,362.86Zm.834,3.087a.834.834,0,1,1-.834-.835A.82.82,0,0,1,233.328,365.947Z"
                transform="translate(-223.93 -350.352)"
                fill="#fff"
              />
              <path
                id="Path_3030"
                data-name="Path 3030"
                d="M413.064,362.86a3.059,3.059,0,1,0,3.059,3.059,3.068,3.068,0,0,0-3.059-3.059Zm0,3.921a.835.835,0,1,1,.834-.834A.82.82,0,0,1,413.064,366.781Z"
                transform="translate(-396.425 -350.352)"
                fill="#fff"
              />
              <path
                id="Path_3031"
                data-name="Path 3031"
                d="M114.252,73.265l-.318-1.122A1.942,1.942,0,0,0,112,70.86h-2.07a.926.926,0,1,0,0,1.832h1.964l2.415,8.361a1.942,1.942,0,0,0,1.937,1.283h10.7a1.936,1.936,0,0,0,1.911-1.26l2.468-7.812Z"
                transform="translate(-108.865 -70.86)"
                fill="#fff"
              />
            </svg>

            <span className="absolute top-0 right-0 md:top-[4px] ml-2 text-[11px] font-medium text-white">
              {itemCount}
            </span>
            <span className="sr-only">items in cart, view bag</span>
          </Popover.Button>

          <Popover.Panel className="absolute z-[100] top-full inset-x-0 mt-px pb-4 bg-white shadow-lg lg:top-full lg:left-auto lg:right-0 lg:mt-2 lg:-mr-1.5 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5">
            <h2 className="sr-only">Shopping Cart</h2>
            <Suspense fallback={<MiniCartLoader />}>
              <MiniCart isOpen={open} close={close} />
            </Suspense>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default CartTrigger;
